.home-background {
    background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url('../../assets/inspiring-bd-large-cover.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
}

.custom-control-label {
  color: $color-primary !important;
  &:hover {
    color: $color-danger !important;
  }
}

.text-primary {
  color: $color-primary !important;
}