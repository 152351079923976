.custom-width {
  width: 15%;
  max-width: none !important;
}

.load-wrap {
  align-content: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin: 0 10px 10px 0;
  padding: 20px 20px 20px;
  border-radius: 5px;
  text-align: center;
  flex-direction: column;
}

.load-wrap p {padding: 0 0 20px;}

.loader5 {
  display:inline-block;
  width: 0;
  height:0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid $color-danger;
  border-top: 20px solid $color-primary;
  -webkit-animation: loader5 1.2s ease-in-out infinite alternate;
  animation: loader5 1.2s ease-in-out infinite alternate;
}

@keyframes loader5 {
  from {transform: rotate(0deg);}
  to {transform: rotate(720deg);}
}
@-webkit-keyframes loader5 {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(720deg);}
}
