.alert-container {
  position: fixed;
  bottom: 15px;
  width: 500px;
  right: 15px;
  margin: 0;
  z-index: 9999;

  @include breakpoint(small-only) {
    max-width: calc(100% - 40px);
    padding: 10px 15px;
    right: 20px;

    .alert-heading {
      font-size: 16px;
      font-weight: bold;
    }

    p {
      font-size: 13px;
      line-height: 16px;
    }
  }
}

.background-card {
  display: flex;
  min-height: 100vh;
  width: 600px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  @include breakpoint(small-only) {
    width: 85%;
  }
}

.home-carousel {
  height: 75vh;
}

.centered {
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: calc(100vh - 5.9vmax);
}

.bold-text {
  font-size: 2em;
  font-weight: 700;
}

.primary-text {
  color: $color-primary;
  font-size: 2em;
}

.danger-text {
  font-size: 2em;
  color: $color-danger;
}

.btn-success {
  background-color: $color-primary;
  border-color: $color-primary;
}

.btn-danger {
  background-color: $color-danger;
  border-color: $color-danger;
}


.current-project {
  padding: 30px 20px;
  background-color: rgba(255, 255, 255, .97);
  box-shadow: 0 0 30px rgba(0,0,0,0.1);
}

.new-nav-item {
  color: $color-primary !important;
  &:hover {
    color: $color-danger !important;
  }
}