.table-title {
  font-size: 1.25em;
  font-weight: 700;
  letter-spacing: .6px;
  color: #222b45;
}

.cut-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-style {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}