@mixin breakpoint($range) {
  $small-upper-boundary: map-get($breakpoints, medium);

  @if $range == "small-only" {
    @media (max-width: #{$small-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == "small-up" {
    @media (min-width: #{$small-upper-boundary}) {
      @content;
    }
  }
}
