.project-landing-card {
    width: 30vw;
    height: 300px;
    border-radius: 12px; 
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}

.project-landing-page {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.page-margin {
    margin: 20px;
}

.project-info-card {
    padding: 20px;
    margin-bottom: 20px;
    @include breakpoint(small-only) {
        text-align: center;
    }
}

.info-header {
    font-size: 0.95rem;
}

.semi-bold {
    font-weight: 500;
}